import React, {Component} from 'react'
import {withRouter, Route, Switch} from 'react-router-dom'

import Personnel from './components/personnel'
import Commercial from './components/commercial'
import Inquiries from './components/inquiries'
import About from './components/about'
import Transactions from './components/transactions'
import Home from './components/home'
import Internship from './components/internship'
import saraAbout from './components/sara-about'

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/Commercial" component={Commercial} />
        <Route path="/Inquiries" component={Inquiries} />
        <Route path="/Transactions" component={Transactions} />
        <Route path="/About" component={About} />
        <Route path="/Personnel" component={Personnel} />
        <Route path="/home" component={Home} />
        <Route path="/internship" component={Internship} />
        <Route path="/saraAbout" component={saraAbout} />

        <Route path="/" component={Home} />
      </Switch>
    )
  }
}

export default withRouter(Routes)
