import React, {Component} from 'react'
import Navbar from './navbar'

import {Helmet} from 'react-helmet'
import Footer from './footer'

class Home extends Component {
  render() {
    return (
      <div>
        {/* <Helmet>
          <script src="/CreateX/dist/js/theme.min.js" />
          <script src="/CreateX/dist/js/card.min.js" />
          <script src="/CreateX/dist/js/modernizr.min.js" />
          <script src="/CreateX/dist/js/vendor.min.js" />
        </Helmet> */}
        <Navbar />

        <section
          className="owl-carousel dots-inside controls-hidden-mobile"
          data-owl-carousel='{ "nav": true, "dots": true, "loop": true, "autoplay": true, "autoplayTimeout": 7000 }'
        >
          <div
            className="bg-cover bg-center bg-no-repeat py-5"
            style={{backgroundImage: 'url(assets/teton-wheat.jpg)'}}
          >
            <span className="bg-overlay" />
            <div className="container bg-content py-md-5">
              <div className="row justify-content-center py-md-5">
                <div className="col-12 text-center py-md-5">
                  <div
                    className="d-table w-100 bg-no-repeat bg-center"
                    style={{
                      minHeight: 448,
                      backgroundSize: '452px 448px',
                    }}
                  >
                    <div className="d-table-cell align-middle">
                      <h2 className="display-4 text-white">
                        <em className="font-weight-light">
                          Jackson Office Now Open!
                        </em>
                      </h2>
                      <p className="text-xl text-white opacity-75 pb-4">
                        New location, new oppurtunities same old number, call
                        (312) 220-0051.
                      </p>
                      <a className="btn btn-style-4 btn-light" href="/about">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bg-cover bg-center bg-no-repeat py-5"
            style={{backgroundImage: 'url(assets/chicagoskynight.jpg)'}}
          >
            <span className="bg-overlay" />
            <div className="container bg-content py-md-5">
              <div className="row justify-content-center py-md-5">
                <div className="col-12 text-center py-md-5">
                  <div
                    className="d-table w-100 bg-no-repeat bg-center"
                    style={{
                      minHeight: 448,
                      backgroundSize: '452px 448px',
                    }}
                  >
                    <div className="d-table-cell align-middle">
                      <h2 className="display-4 text-white">
                        <em className="font-weight-light">
                          Prairie Realty Advisors
                        </em>
                      </h2>
                      <p className="text-xl text-white opacity-75 pb-4">
                        Over $3.0 billion in commercial financing since
                        September 1992.
                      </p>
                      <a
                        className="btn btn-style-4 btn-light"
                        href="/about"
                        // onClick={() => {
                        //   this.setTimeout(50, window.location.reload())
                        // }}
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bg-cover bg-center bg-no-repeat py-5"
            style={{backgroundImage: 'url(assets/tetons4.jpeg)'}}
          >
            <span className="bg-overlay" style={{opacity: '.55'}} />
            <div className="container bg-content py-md-5">
              <div className="row justify-content-center py-md-5">
                <div className="col-12 text-center py-md-5">
                  <div
                    className="d-table w-100 bg-no-repeat bg-center"
                    style={{
                      minHeight: 448,
                      backgroundSize: '452px 448px',
                    }}
                  >
                    <div className="d-table-cell align-middle">
                      <h2 className="display-4 text-white">
                        <em className="font-weight-light">
                          Prairie Realty Advisors
                        </em>
                      </h2>
                      <p className="text-xl text-white opacity-75 pb-4">
                        Servicing Portfolio of over $300 million.
                      </p>
                      <a
                        className="btn btn-style-4 btn-light"
                        href="/about"
                        // onClick={() => {
                        //   this.setTimeout(50, window.location.reload())
                        // }}
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
        <a className="scroll-to-top-btn" href="#">
          <i className="fe-icon-chevron-up" />
        </a>

        <div className="site-backdrop" />
      </div>
    )
  }
}

export default Home
