import React from 'react'

const Navbar = () => (
  <div>
    <div
      className="offcanvas-container is-triggered offcanvas-container-reverse"
      id="mobile-menu"
    >
      <span className="offcanvas-close">
        <i className="fe-icon-x" />
      </span>
      <div className="px-4 pb-4">
        <h6>Menu</h6>
      </div>
      <div
        className="offcanvas-scrollable-area border-top"
        style={{height: 'calc(100% - 235px)', top: 144}}
      >
        <div className="accordion mobile-menu" id="accordion-menu">
          <div className="card">
            <div className="card-header">
              <a
                className="mobile-menu-link"
                href="/home"
                onClick={() => {
                  setTimeout(50, window.location.reload())
                }}
              >
                Home
              </a>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <a
                className="mobile-menu-link"
                href="/internship"
                onClick={() => {
                  setTimeout(50, window.location.reload())
                }}
              >
                Internship
              </a>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <a
                className="mobile-menu-link"
                href="/about"
                onClick={() => {
                  setTimeout(50, window.location.reload())
                }}
              >
                Why Us
              </a>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <a
                className="mobile-menu-link"
                href="/personnel"
                onClick={() => {
                  setTimeout(50, window.location.reload())
                }}
              >
                Team
              </a>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <a
                className="mobile-menu-link"
                href="/commercial"
                onClick={() => {
                  setTimeout(50, window.location.reload())
                }}
              >
                Expertise and Inquiries
              </a>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <a
                className="mobile-menu-link"
                href="/saraAbout"
                onClick={() => {
                  setTimeout(50, window.location.reload())
                }}
              >
                SARA Capital Markets
              </a>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <a
                className="mobile-menu-link"
                href="/transactions"
                onClick={() => {
                  setTimeout(50, window.location.reload())
                }}
              >
                Transactions
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <header className="navbar-wrapper navbar-boxed navbar-simple-ghost">
      <div className="container-fluid">
        <div className="d-table-cell align-middle pr-md-3" id="wider-logo">
          <a className="navbar-brand mr-1" id="wider-a" href="index.html">
            <img src="assets/pralogonew.png" alt="photo" />
          </a>
        </div>
        <div className="d-table-cell w-100 align-middle pl-md-3">
          <div className="navbar justify-content-end justify-content-lg-between">
            <form className="search-box" method="get">
              <input
                type="text"
                id="site-search"
                placeholder="Type A or C to see suggestions"
              />
              <span className="search-close">
                <i className="fe-icon-x" />
              </span>
            </form>

            <ul className="navbar-nav d-none d-lg-block">
              <li className="nav-item mega-dropdown-toggle">
                <a
                  className="nav-link"
                  href="/home"
                  onClick={() => {
                    setTimeout(50, window.location.reload())
                  }}
                >
                  Home
                </a>
              </li>

              <li className="nav-item mega-dropdown-toggle">
                <a
                  className="nav-link"
                  href="/about"
                  onClick={() => {
                    setTimeout(50, window.location.reload())
                  }}
                >
                  Why Us
                </a>
              </li>

              <li className="nav-item mega-dropdown-toggle">
                <a
                  className="nav-link"
                  href="personnel"
                  onClick={() => {
                    setTimeout(50, window.location.reload())
                  }}
                >
                  Team
                </a>
              </li>

              <li className="nav-item dropdown-toggle">
                <a
                  className="nav-link"
                  href="/commercial"
                  onClick={() => {
                    setTimeout(50, window.location.reload())
                  }}
                >
                  Expertise and Inquiries
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/transactions">
                  Transactions
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/saraAbout"
                  onClick={() => {
                    setTimeout(500, window.location.reload())
                  }}
                >
                  SARA Capital Markets
                </a>
              </li>
              <li className="nav-item dropdown-toggle">
                <a
                  className="nav-link"
                  href="/internship"
                  onClick={() => {
                    setTimeout(50, window.location.reload())
                  }}
                >
                  Internship
                </a>
              </li>
            </ul>
            <div>
              <ul className="navbar-buttons d-inline-block align-middle">
                <li className="d-block d-lg-none">
                  <a href="#mobile-menu" data-toggle="offcanvas">
                    <i className="fe-icon-menu" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
)

export default Navbar
