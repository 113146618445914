import React, {Component} from 'react'
import Navbar from './navbar'
import Footer from './footer'
import LenderCarousel from './lendercarousel'

class About extends Component {
  render() {
    return (
      <div>
        <Navbar />

        <div
          className="page-title d-flex"
          aria-label="Page title"
          style={{backgroundImage: 'url(assets/tetons5.jpg)'}}
        >
          <div className="container text-left align-self-center">
            <nav aria-label="breadcrumb"></nav>
            <h1 className="page-title-heading" style={{color: 'white'}}>
              Why Us
            </h1>
          </div>
        </div>

        <div className="container pb-5 mb-3">
          <h1>Jackson Office Now Open</h1>

          <section className="container my-5">
            <div className="bg-secondary px-3 py-5">
              <div className="row justify-content-center">
                <div
                  className="col-xl-8 col-lg-10 pt-3 text-center"
                  id="no-padd"
                >
                  <h2 id="font-boi">
                    Now serving an area that extends from Cheyenne to Rock
                    Springs and Idaho Falls to Bozeman. Helping investors in
                    institutional real estate find attractive, competitve
                    capital.
                  </h2>
                </div>
              </div>
            </div>
          </section>

          <h1>The Facts</h1>
          <ul className="list-group">
            <li className="list-group-item">
              Over $3.0 billion in commercial financing since September 1992.
            </li>
            <li className="list-group-item">
              Coverage of the U. S. as well as Canada, Mexico and Europe in
              2003.
            </li>
            <li className="list-group-item">
              Six commercial real estate finance professionals.
            </li>
            <li className="list-group-item">
              Servicing Portfolio of over $300 million.
            </li>
            <li className="list-group-item">
              Twelve(12) correspondent lender relationships.
            </li>
            <li className="list-group-item">Over 400 financings since 2000.</li>
          </ul>
          <br />
          <br />
          <div className="d-md-table w-100 p-4 p-lg-5 mb-30 box-shadow">
            <div
              className="d-md-table-cell align-middle mx-auto mb-4 mb-md-0"
              style={{width: 150}}
            >
              <img
                className="d-block w-100"
                src="assets/whatwedo.svg"
                alt="Delivery Worldwide"
              />
            </div>
            <div className="d-md-table-cell align-middle pl-md-4 pl-lg-5 text-center text-md-left">
              <h3 className="h4">What We Do</h3>
              <p className="text-muted">
                As a mortgage banker, PRA originates, sells and services
                commercial real estate loans. In return for providing these
                services, the Company receives remuneration in the form of fees
                from borrowers or clients for originating the transaction, and
                servicing override fees for "servicing" loans for a lender. PRA
                has relationships with many institutional lenders throughout the
                U.S. The firm is able to provide capital to borrowers for loans
                ranging from $2,000,000 and above.
              </p>
            </div>
          </div>

          <div className="d-md-table w-100 p-4 p-lg-5 mb-30 box-shadow">
            <div
              className="d-md-table-cell align-middle mx-auto mb-4 mb-md-0"
              style={{width: 150}}
            >
              <img
                className="d-block w-100"
                src="assets/earth.svg"
                alt="Mobile App"
              />
            </div>
            <div className="d-md-table-cell align-middle pl-md-4 pl-lg-5 text-center text-md-left">
              <h3 className="h4">Who We Serve</h3>
              <p className="text-muted">
                With offices in Chicago and Jackson, Wyoming the firm services a
                direct market of over 16 million people. PRA takes special pride
                in the fact that it is employed by its clients across geographic
                boundaries. It works hard to create a bond between lender and
                borrower in an attempt to realizing a higher percentage of
                repeat business.
              </p>
            </div>
          </div>

          <div className="d-md-table w-100 p-4 p-lg-5 box-shadow">
            <div
              className="d-md-table-cell align-middle mx-auto mb-4 mb-md-0"
              style={{width: 150}}
            >
              <img
                className="d-block w-100"
                src="assets/bandlogo.png"
                alt="Outlet Stores"
              />
            </div>
            <div className="d-md-table-cell align-middle pl-md-4 pl-lg-5 text-center text-md-left">
              <h3 className="h4">Who We Are</h3>
              <p className="text-muted">
                As of the 1st Q of 2021, the firm consists of a team of six.
                There are 15 additional former employees who we recognize for
                their contributions in helping in PRA’s success over the past 29
                years.
                <br />
                <br />
                PRA current team of professionals have over 100 years of
                combined real estate industry experience. PRA has always been a
                member of the Mortgage Bankers Association (“MBA”). It has held
                licenses from the State of Illinois and Wisconsin and supported
                ULI for many years. Loan production and corporate governance is
                John’s job. Loan production and lender cultivation are Alby's
                and James' focus. Angie, Jon, and Steve provide overview and
                back office organization and stability.
              </p>
            </div>
          </div>

          <LenderCarousel />
        </div>

        <Footer />

        <a className="scroll-to-top-btn" href="#">
          <i className="fe-icon-chevron-up" />
        </a>
        <div className="site-backdrop" />
      </div>
    )
  }
}

export default About
