import React, {Component} from 'react'
import Navbar from './navbar'
import Footer from './footer'
import TransCard from './trans-card'
import GridView from './gridview'

class Transactions extends Component {
  constructor() {
    super()
    this.state = {
      showGrid: false,
    }
  }

  toggleGrid = () => {
    if (!this.state.showGrid) {
      this.setState({showGrid: true})
    } else {
      this.setState({showGrid: false})
      window.location.reload()
    }
  }
  render() {
    return (
      <div>
        <Navbar />

        <div
          className="page-title d-flex"
          aria-label="Page title"
          style={{backgroundImage: 'url(assets/tetons5.jpg)'}}
        >
          <div className="container text-left align-self-center">
            <nav aria-label="breadcrumb"></nav>
            <h1 className="page-title-heading" style={{color: 'white'}}>
              Transactions
            </h1>
          </div>
        </div>

        <div className="pl-xl-5" id="more-padding">
          <div className="d-sm-flex justify-content-between text-center text-sm-left">
            <h2 className="h4">Representative Transactions</h2>
            <a
              className="btn btn-style-4 btn-primary btn-sm"
              href="#"
              id="new-button"
              onClick={this.toggleGrid}
            >
              Grid View
            </a>
          </div>
          {!this.state.showGrid ? (
            <div className="row pt-4">
              <div
                className="owl-carousel"
                id="carousel"
                data-owl-carousel='{ "nav": true, "dots": true, "loop": true, "margin": 30, "autoplay": true, "autoplayTimeout": 4000, "responsive": {"0":{"items":1},"630":{"items":2},"991":{"items":3},"1200":{"items":5}} }'
              >
                <TransCard
                  image="DenverPhoto.jpg"
                  location="Industrial Building"
                  company="Life Company"
                  price="$2,000,000+"
                />
                <TransCard
                  image="ElginPhoto.png"
                  location="Neighborhood Retail Center"
                  company="Bank"
                  price="$1,600,000"
                />
                <TransCard
                  image="Walgreens.jpg"
                  location="Walgreens Drugs"
                  company="Life Company"
                  price="$3,800,000"
                />
                <TransCard
                  image="OrlandPhoto.jpg"
                  location="Medical Office Building"
                  company="Life Company"
                  price="$2,200,000"
                />
                <TransCard
                  image="Oneidaphoto.jpg"
                  location="Cold Storage Distribution"
                  company="Life Company"
                  price="$19,500,000"
                />
                <TransCard
                  image="MSUPhoto2014v2.jpg"
                  location="Dedicated Student Housing"
                  company="Agency Lender"
                  price="$19,000,000"
                />
                <TransCard
                  image="kindercare.jpg"
                  location="Specialty Retail"
                  company="Life Company"
                  price="$3,800,000"
                />
                <TransCard
                  image="marketsquarephoto.jpg"
                  location="Upscale Specialty Retail"
                  company="CMBS"
                  price="$29,000,000"
                />
                <TransCard
                  image="waukphoto.jpg"
                  location="Medical Office Buildings"
                  company="CMBS"
                  price="$20,000,000+"
                />
                <TransCard
                  image="newprop6.jpg"
                  location="Corporate Headquarters"
                  company="Life Company"
                  price="$20,000,000+"
                />
                <TransCard
                  image="LUMCprop.jpg"
                  location="Condo Medical Office Building"
                  company="Life Company"
                  price="$1,300,000"
                />
                <TransCard
                  image="StaybridgeGlenviewPhoto.jpg"
                  location="Staybridge Suites Hotel"
                  company="CMBS"
                  price="$10,000,000+"
                />
                <TransCard
                  image="UofIphotov2.jpg"
                  location="Dedicated Student Housing"
                  company="Bank and Agency"
                  price="$5,500,000"
                />
                <TransCard
                  image="newprop1.jpg"
                  location="Dedicated Student Housing"
                  company="Bank and Agency"
                  price="$10,500,000"
                />

                <TransCard
                  image="newprop2.jpg"
                  location="Freezer Distribution Building"
                  company="Combo Construction Perm"
                  price="$18,000,000"
                />
                <TransCard
                  image="newprop3.jpg"
                  location="Dedicated Student Housing"
                  company="Bank and Agency"
                  price="$7,300,000"
                />

                <TransCard
                  image="pointe5oaks.jpg"
                  location="Market Apartments"
                  company="Bank and Agency"
                  price="$16,500,000"
                />
                <TransCard
                  image="flexindus.jpg"
                  location="Flex Industrial"
                  company="Bank"
                  price="$1,350,000"
                />
                <TransCard
                  image="aldi.JPG"
                  location="Aldi"
                  company="Insurance Co"
                  price="$1,960,000"
                />
                <TransCard
                  image="retailstrip62.jpg"
                  location="Retail Strip"
                  company="Bank"
                  price="$3,000,000"
                />
                <TransCard
                  image="retail.jpg"
                  location="Retail"
                  company="Insurance Co"
                  price="$1,250,000"
                />
                <TransCard
                  image="walmart.jpg"
                  location="Walmart Neighborhood Market"
                  company="Term Loan"
                  price="$6,800,000"
                />
                <TransCard
                  image="lincolnparkapt.jpg"
                  location="Lincoln Park Apartments"
                  company="Bank"
                  price="$2,350,000"
                />
                <TransCard
                  image="industrial.jpg"
                  location="Industrial"
                  company="Bank"
                  price="$3,500,000"
                />
                <TransCard
                  image="appartmentpor.jpg"
                  location="Apartment Portfolio"
                  company="Agency Lender"
                  price="$7,200,000"
                />
              </div>
            </div>
          ) : (
            <GridView />
          )}
        </div>

        <Footer />

        <a className="scroll-to-top-btn" href="#">
          <i className="fe-icon-chevron-up" />
        </a>

        <div className="site-backdrop" />
      </div>
    )
  }
}

export default Transactions
