import React from 'react'

import Navbar from './navbar'
import Footer from './footer'

const Internship = () => {
  return (
    <div>
      <Navbar />

      <div
        className="page-title d-flex"
        aria-label="Page title"
        style={{backgroundImage: 'url(assets/tetons5.jpg)'}}
      >
        <div className="container text-left align-self-center">
          <nav aria-label="breadcrumb"></nav>
          <h1 className="page-title-heading" style={{color: 'white'}}>
            Internships
          </h1>
        </div>
      </div>

      <div className="container pb-5 mb-3">
        <div className="inquiries">
          <section className="container mb-5 pb-3">
            <div className="wizard">
              <div className="wizard-body pt-3">
                <h2 className="h4 text-center">Internship</h2>
                <p className="text-muted text-center">
                  Investing in the future with PRA
                </p>

                <form
                  className="needs-validation"
                  action="https://formspree.io/f/mrgdpdej"
                  method="POST"
                  noValidate
                >
                  <div className="row pt-3">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="contact-name">
                          Your Name{' '}
                          <span className="text-danger font-weight-medium">
                            *
                          </span>
                        </label>
                        <input
                          name="name"
                          className="form-control"
                          type="text"
                          id="contact-name"
                          placeholder="John Doe"
                          required
                        />
                        <div className="invalid-feedback">
                          Please enter your name!
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="contact-email">
                          Your Email{' '}
                          <span className="text-danger font-weight-medium">
                            *
                          </span>
                        </label>
                        <input
                          name="email"
                          className="form-control"
                          type="email"
                          id="contact-email"
                          placeholder="johndoe@email.com"
                          required
                        />
                        <div className="invalid-feedback">
                          Please provide a valid email address!
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="contact-subject">Subject</label>
                        <input
                          name="subject"
                          className="form-control"
                          type="text"
                          id="contact-subject"
                          placeholder="Provide short title of your request"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="contact-message">
                      Message{' '}
                      <span className="text-danger font-weight-medium">*</span>
                    </label>
                    <textarea
                      name="message"
                      className="form-control"
                      rows={7}
                      id="contact-message"
                      placeholder="Let us know more what's on your mind..."
                      required
                      defaultValue={''}
                    />
                    <div className="invalid-feedback">
                      Please write a message!
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      className="btn btn-primary"
                      id="new-button"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
      <a className="scroll-to-top-btn" href="#">
        <i className="fe-icon-chevron-up" />
      </a>
      <div className="site-backdrop" />
    </div>
  )
}

export default Internship
